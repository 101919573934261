import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="z4jadFtInKg?si=BdywMCl3nPuTx0-T" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <React.Fragment>
        <p>
          「コミュニケーションを活性化し、エンゲージメントを高めるオフィス」<br />
          ニューノーマルな時代に、あえてリアルなオフィス空間を手がける乃村工藝社。<br />
          そんな乃村工藝社社員が自らプロデュースしたユニークな自社オフィスと、そこで働く乃村工藝社社員の多様な働き方を映像にしました。
        </p>
        <dl className="dlWorkCredit mb-0">
          <dt>Producer：</dt>
          <dd>松瀬 友樹（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit mb-0">
          <dt>Production Manager：</dt>
          <dd>森川 大聖（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit mb-0">
          <dt>Cinematographer：</dt>
          <dd>小久保 尚志（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit">
          <dt>Compositer：</dt>
          <dd>大賀 成真（MONTAGE Inc.）<br />佐藤 菜南（MONTAGE Inc.）</dd>
        </dl>
        <div className="links">
          <a href="https://www.nomurakougei.co.jp/achievements/page/reset-space2/" target="_blank" rel="noreferrer">
            乃村工藝社がつくるワークプレイス<span>↗</span>
          </a><br />
          <a href="https://www.nomurakougei.co.jp/recruit/environment/" target="_blank" rel="noreferrer">
            働く環境 | 株式会社乃村工藝社<span>↗</span>
          </a>
        </div>
      </React.Fragment>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Direction', 'Video Production'],
    awards: [],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(1, 4)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/nomura_workplace"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/nomura_workplace.*"}) {
      publicURL
    }
  }
`;
